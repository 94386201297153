import { Api } from "@leapdev/leap-host";
import { IDocModelDto } from "../dto/document.dto";
import { environment } from "../environments";

export const close = (sdkApi?: Api) => {
  if (sdkApi) {
    if (!!sdkApi.leapContext.context.matterId)
      reload(sdkApi.leapContext.context.matterId, sdkApi);
    sdkApi.system.close();
  }
};

export const reload = (matterId: string, sdkApi: Api) => {
  sdkApi.accounting.reloadTimeFeeList({ matterId: matterId });
  sdkApi.accounting.reloadCostRecoveryList({ matterId: matterId });
  sdkApi.accounting.reloadOfficeLedger({ matterId: matterId });
};

export const openDocument = (docModel: IDocModelDto, sdkApi?: Api) => {
  if (sdkApi && docModel.documentId) {
    try {
      sdkApi.document.openDocument({
        documentId: docModel.documentId,
        timeOut: 10,
        matterId: docModel.matterId || "",
      } as any);
    } catch (error) {
      console.error(error);
    }
  }
};

export function openMatter(matterId: string, sdkApi?: Api) {
  if (!!sdkApi) {
    try {
      sdkApi.matter.openMatter({
        matterId: matterId,
      });
    } catch (error) {
      console.warn(error);
    }
  }
}

export async function openReport(invoiceId: string, sdkApi?: Api) {
  if (sdkApi && invoiceId) {
    const authToken = await sdkApi.getRefreshedAccessToken();
    const urlReport = `${
      environment().invoicePrintUrl
    }?src=Offex&rpt=Office+Invoice+header+single&rs:Command=Render&rs:Format=PDF&InvoiceGUID=${invoiceId}&access_token=${authToken}`;
    try {
      await sdkApi.system.openUrl({
        openExternal: true,
        url: urlReport,
        windowTitle: "",
      });
    } catch (error) {
      console.error(error);
    }
  }
}

export const openTwitter = (sdkApi?: Api) => {
  if (sdkApi) {
    sdkApi.system.openTwitter({});
  }
};

export const openUrl = (
  openExternal: boolean,
  url: string,
  windowTitle: string,
  sdkApi?: Api
) => {
  if (sdkApi) {
    try {
      sdkApi.system.openUrl({
        openExternal: openExternal,
        url: url,
        windowTitle: windowTitle,
      });
    } catch (error) {
      console.error(error);
    }
  }
};

export async function getDecodedToken(sdkApi?: Api) {
  if (!!sdkApi) {
    try {
      const decodedToken = await sdkApi
        .getDecodedRefreshedAccessToken()
        .then((decodedToken) => {
          return decodedToken;
        });
      return decodedToken;
    } catch (error) {
      console.error(error);
    }
  }
}

export async function onBeforeClose(action: () => void, sdkApi?: Api) {
  if (!!sdkApi) {
    try {
      sdkApi.registerHostMessageHandler("onBeforeClose", () => {
        sdkApi.system.onBeforeClose(() => {
          action();
          return new Promise<boolean>((resolve) => {
            setTimeout(() => resolve(true), 200);
          });
        });
      });
    } catch (error) {
      console.error(error);
    }
  }
}

export async function setWindowTitle(
  title: string,
  sdkApi?: Api,
  maxLength?: number,
  appendToEnd?: string
) {
  if (!!sdkApi) {
    try {
      if (maxLength) {
        if (title.length > maxLength)
          title = title.slice(0, maxLength - 3).concat("...");
      }
      const response = await sdkApi.system.setWindowTitle({
        title:
          appendToEnd !== undefined ? title.concat(" ", appendToEnd) : title,
      });

      return response;
    } catch (error) {
      console.error(error);
    }
  }
}
